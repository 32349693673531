<template>
	<div id="privacy" class="pb-0">
		<v-row class="pa-0 ma-0" align="center" no-gutters>
			<v-col cols="12" class="px-0 primary bg-wavelines-secondary-dark white--text">
				<div class="mx-auto mb-5 pb-5" id="how" style="max-width: 1200px;">
					<h2 class="display-1 mt-md-5 pt-10 mb-1 text-center text-md-h3 shadowed">Privacy Policy</h2>
					<p class="title mb-0 lh-14 text-center mx-auto mb-3 mb-md-5 shadowed" style="max-width: 600px;">
						I&rsquo;mOkay.me
					</p>
				</div>
			</v-col>
		</v-row>
		<v-row class="white mt-0">
			<v-col cols="12">
				<div class="mx-auto mb-5 pb-5 px-5 px-md-1 pt-md-10 secondary--text" id="how" style="max-width: 1000px;">
					<v-row>
						<v-col cols="12" class="hidden-sm-and-down">
							<h1 class="text-h4 text-center text-md-left">
								I&rsquo;m Okay Privacy Policy
							</h1>
						</v-col>
						<v-col cols="12">
							<v-alert type="info" class="mt-3  mt-md-0 mb-0 body-2 font-weight-medium text-left white--text">
								<h3>Please, at least read this!</h3>
								<p class="mb-1 mt-1">While we take great measures to protect your data from unauthorized access, there is still a risk of your personal information being seen by others (e.g. somebody gains access to your phone/browser, etc.). Please excercise caution by logging-out of any public or other non-personal browsers and devices.</p>
								<p class="mb-0">We do not advertise on our website, therefore none of your data or any collected marketing information, including your email address, is shared to any 3rd-party advertisers. Data collected from your browser is used solely to gather analytics for use in monitoring and improving our services.</p>
							</v-alert>
						</v-col>
						<v-col cols="12">
							<h4>Our Privacy Policy</h4>
							<p>You take online privacy seriously, and so does the I&rsquo;mOkay.me. The terms “we,” “our,” and “us” in this Notice refer to I&rsquo;mOkay.me</p>
							<p>Our Online Privacy Notice (the “Notice”) describes the information we collect from and about you during your visit to our online interfaces (websites and mobile applications) owned or controlled by us (the “Site”). It also
								describes how we use information about you, with whom we share it and how we protect it. This Notice applies only to those I&rsquo;mOkay.me interfaces that link directly to this Notice.</p>
							<p>It's important to know that our websites may contain links to third party sites not controlled by I&rsquo;mOkay.me or covered by this Notice. We recommend that you check the privacy statements of other sites you visit before providing
								any personal information.</p>
							<h4>Personal Information We Collect</h4>
							<p>We collect Personal information for I&rsquo;mOkay.me Identifiable Information ("PII") about you from the information you provide to us when you visit our websites. The information we collect will depend on the Site you visit. This information may include,
								but is not limited to:</p>
							<ul>
								<li>Name</li>
								<li>Address</li>
								<li>Phone numbers (including mobile)</li>
								<li>Email address</li>
								<li>Internet Protocol (IP) Address and/or domain</li>
								<li>Geo-location of your computer or mobile device</li>
							</ul>
							<p class="mt-5">We use personal information collected online for various functions, which may include:</p>
							<ul>
								<li>processing your request for services, products or information</li>
								<li>improving our customer service and products</li>
								<li>servicing your account</li>
								<li>facilitating your participation in online activities.</li>
							</ul>
							<p class="mt-5">For example, we may use the information you provide when you complete an online application for a product or service in order to process and evaluate your application. If you request product information, we may use the information
								you provide to mail you information you request.</p>
							<p>We may also use the information you provide to contact you regarding your account. In addition, we may collect and use information for analytic purposes, market research or for marketing purposes, as allowed by law.</p>
							<h4>How Personal Information Is Shared</h4>
							<p>We may share the personal information we collect about you with third parties, such as companies performing services on our behalf to provide the products or services you have requested.</p>
							<p>As permitted by law, we may also share information collected about you with companies with whom we have formal agreements to offer you products and services.</p>
							<p>We may share information about you with our affiliates (other companies in the I&rsquo;mOkay.me family) and with nonaffiliates for analysis, market research and marketing purposes as allowed by law.</p>
							<p>We may disclose your personal information as otherwise permitted or required by law.</p>
							<h4>Information collected via Cookies </h4>
							<p>We use a variety of technologies, including <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank">cookies</a>, to collect information about your online/mobile activity when you visit our website. Cookies are small text files that a website server stores on your computer or device.
								They’re typically used to remember your account login preferences, monitor website traffic, provide customer support like live chat and help us better customize our site for your individual preferences.</p>
							<p>You can disable cookies by making the appropriate selection from your browser options to inform you when cookies are set or to prevent cookies from being set. However, if you choose to disable or delete cookies, you may limit
								the functionality we can provide when you visit our site.</p>
							<p>The third parties we contract with may use cookies or other technologies to gather interest-based information to customize the ads you see. Neither the third parties nor I&rsquo;mOkay.me collects any personI&rsquo;mOkay.me identifiable information
								(PII) through these cookies. We may also use aggregate data to display ads or content that may interest you. </p>
							<p>I&rsquo;mOkay.me has adopted the use of the <a href="http://www.youradchoices.com/" target="_blank" rel="external">AdChoices Icon</a> (also known as the Advertising Option Icon) on our interest-based advertising (excluding ads appearing on platforms
								that do not accept the icon). You can click the AdChoices Icon to learn more and control whether or not you receive this type of advertising from participating companies.</p>
							<h3>Device-Based Advertising</h3>
							<p>We also use device information, particularly in mobile advertising, to help deliver our ads and measure ad campaign effectiveness. I&rsquo;mOkay.me and the companies we work with won’t collect any personI&rsquo;mOkay.me identifiable information
								(PII) but may collect your device information when you visit one of our sites or see our ads. Compiling and comparing this information creates a unique ID for individual devices and can be "matched" to the same information
								on other sites or mobile applications. Some companies we work with are also able to associate related devices into households based on common characteristics, such as IP addresses.</p>
							<!-- <h3>Device Usage Research</h3>
							<p>We participate in the Adobe Marketing Cloud Device Co-op. This helps us understand how you use our website and apps across your devices so that we can better tailor promotions to you. <a href="https://cross-device-privacy.adobe.com" rel="external">Learn more about how Adobe’s program works and manage your preferences.</a></p> -->
							<h4>"Do Not Track" Signals</h4>
							<p>We do not respond to Web browser "Do Not Track" signals at this time. To prevent I&rsquo;mOkay.me or third parties from tracking, you can access your browser settings and disable or delete your cookies.</p>
							<h4>Changing or Updating Your Personal Information </h4>
							<p>If you have questions about personal information you provided on a I&rsquo;mOkay.me website, select the "Contact Us" link – found in the navigation menu – or log into your Runara account and select Account Information to edit
								your personal information or your I&rsquo;mOkay.me account and select My Profile to edit your personal information.</p>
							<h4>Security Of Your Personal Information  </h4>
							<p>We restrict access to the personal information obtained from our website to only those employees, agents and contractors who need it to do their jobs. We maintain administrative, technical, and physical safeguards designed
								to protect your personal information. Your passwords are never stored in their plaintext forms, and all stored data is encrypted after entry to further safegaurd your information.</p>
							<p>Additionally, companies that provide online services on our behalf are required by contract to protect customer information. They are only allowed to use the information they collect for the purpose of providing the services
								that we have contracted to them.
							</p>
							<h4>Personal Information About Children</h4>
							<p>We do not knowingly collect or maintain personal information about children under the age of 13 unless the personal information is provided by a parent, legal guardian or authorized adult. Our websites are not intended for
								children under 13. Visit the Federal Trade Commission website for more information on the <a href="https://www.consumer.ftc.gov/articles/0031-protecting-your-childs-privacy-online" target="_blank" rel="external">Children’s Online Privacy Protection Act (COPPA).</a></p>
							<h4>Public Forums Available On Our Sites And Social Media</h4>
							<p>Public forums are available on our Sites, and they may include text, photographs and videos that are accessible to the general public. Unless we tell you otherwise, any information you disclose in these areas may become public
								information. </p>
							<p>We also provide experiences through social media sites such as Facebook, Twitter, LinkedIn and YouTube. Any and all information you share on these Social Media sites is visible to any visitor to these sites. Refer to each social
								media site for their Terms of Use and Privacy Policies.</p>
							<p>You should use caution when deciding to disclose personal information in these areas.</p>
							<h4>Changes to this Notice</h4>
							<p>This Notice is effective Decembery 25th, 2021. We reserve the right to alter this Notice at our discretion. Any change to this Notice will be posted on our web properties in a timely manner.</p>

						</v-col>
						<v-col cols="12" class="pb-10">
							<v-divider class="mb-5" />
							<h4>Questions?</h4>
							<p>Please <a href="/support">contact us</a> for any questions, comments or concerns regarding our privacy policy.</p>	
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>

export default {
	name: 'Terms',
	data: () => ({
		
	}),
	components: {
	},
	computed: {
	},
	methods: {
		initData(){
		},
	},
	beforeDestroy(){
	},
	mounted(){
		this.initData();
	},
	watch: {
		
	}
}
</script>